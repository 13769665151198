import React from "react"

function AccessibilityDeclarationTemplate() {
  return (
    <div>
      <h1>Deklaracja dostępności</h1>
      <p>
        <span className="italicize">
          Zespół Szkolno- Przedszkolny im. Mikołaja Kopernika w Nidku
        </span>{" "}
        zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z
        przepisami ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron
        internetowych i aplikacji mobilnych podmiotów publicznych. Oświadczenie
        w sprawie dostępności ma zastosowanie do strony internetowej{" "}
        <span className="italicize bold">zspnidek.pl</span>
      </p>
      <p className="italicize bold">
        Zespół Szkolno- Przedszkolny im. Mikołaja Kopernika w Nidku, Nidek ul.
        św. Judy Tadeusza 2, 34-122 Wieprz
      </p>
      <p className="italicize bold">
        tel. <a href="tel:338755811">338755811</a>
      </p>
      <p className="italicize bold">
        e-mail:{" "}
        <a href="mailto:sekretariat@zspnidek.wieprz.pl">
          sekretariat@zspnidek.wieprz.pl
        </a>
      </p>
      <h2 className="bold">Data publikacji i aktualizacji</h2>
      <p>Data publikacji strony internetowej: 1.08.2021</p>
      <p>Data ostatniej istotnej aktualizacji: 1.08.2021</p>
      <h2 className="bold">Status pod względem zgodności z ustawą</h2>
      <p>
        Strona internetowa jest <span className="bold">częściowo zgodna</span> z
        ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron
        internetowych i aplikacji mobilnych podmiotów publicznych.
      </p>
      <h2 className="bold">
        Data sporządzenia deklaracji i metoda oceny dostępności cyfrowej
      </h2>
      <p>Oświadczenie sporządzono dnia: 30 marca 2021 r.</p>
      <p>
        Deklarację sporządzono na podstawie samooceny przez{" "}
        <span className="italicize">podmiot publiczny</span>
      </p>
      <h2 className="bold">Informacje zwrotne i dane kontaktowe</h2>
      <p>
        W przypadku problemów z dostępnością strony internetowej prosimy o
        kontakt. <br /> Osobą kontaktową jest{" "}
        <span className="italicize">mgr Ewa Snażyk-Płonka</span>
      </p>
      <p>
        Kontaktować można się także dzwoniąc na numer telefonu{" "}
        <a href="tel:338755811">33 875 58 11</a>, kontakt mailowy{" "}
        <a href="mailto:sekretariat@zspnidek.wieprz.pl">
          sekretariat@zspnidek.wieprz.pl
        </a>{" "}
        Tą samą drogą można składać wnioski o udostępnienie informacji
        niedostępnej oraz składać skargi na brak zapewnienia dostępności.
      </p>
      <h2 className="bold">Korzystanie z klawiatury</h2>
      <p>
        <span className="bold">Tab</span>: przenosi fokus (punkt uwagi) do
        następnego aktywnego elementu na stronie (odnośnika, formularza){" "}
      </p>
      <p>
        <span className="bold">Shift + Tab</span>: przenosi fokus do
        poprzedniego aktywnego elementu na stronie (odnośnika, formularza){" "}
      </p>
      <p>
        <span className="bold">Enter</span>: uaktywnia odnośnik i przyciski.{" "}
      </p>
      <p>
        Świadomie zrezygnowaliśmy z możliwości szerszego zastosowania skrótów
        klawiaturowych, aby uniknąć konfliktów z technologiami asystującymi (np.
        czytnikami ekranów), systemem lub aplikacjami użytkowników.
      </p>
      <h2 className="bold">Udogodnienia</h2>
      <p>
        Serwis jest wyposażony w udogodnienia ułatwiające przeglądanie treści
        przez osoby niedowidzące:{" "}
      </p>
      <ul>
        <li>przełącznik zmiany rozmiaru czcionki, </li>
        <li>przełączniki zmiany kontrastu, </li>
        <li>
          na stronie zastosowano technologie "skip navigation" – szybkie
          przemieszczanie do wskazanych elementów strony.
        </li>
      </ul>
      <h2 className="bold">Informacje na temat procedury</h2>
      <p>
        Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności
        cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich
        elementu. Można także zażądać udostępnienia informacji za pomocą
        alternatywnego sposobu dostępu, na przykład przez odczytanie
        niedostępnego cyfrowo dokumentu, opisanie zawartości filmu bez
        audiodeskrypcji itp. Żądanie powinno zawierać dane osoby zgłaszającej
        żądanie, wskazanie, o którą stronę internetową lub aplikację mobilną
        chodzi oraz sposób kontaktu. Jeżeli osoba żądająca zgłasza potrzebę
        otrzymania informacji za pomocą alternatywnego sposobu dostępu, powinna
        także określić dogodny dla niej sposób przedstawienia tej informacji.
        Podmiot publiczny powinien zrealizować żądanie niezwłocznie, nie później
        niż w ciągu 7 dni od dnia wystąpienia z żądaniem. Jeżeli dotrzymanie
        tego terminu nie jest możliwe, podmiot publiczny niezwłocznie informuje
        o tym wnoszącego żądanie, kiedy realizacja żądania będzie możliwa, przy
        czym termin ten nie może być dłuższy niż 2 miesiące od dnia wystąpienia
        z żądaniem. Jeżeli zapewnienie dostępności cyfrowej nie jest możliwe,
        podmiot publiczny może zaproponować alternatywny sposób dostępu do
        informacji. W przypadku, gdy podmiot publiczny odmówi realizacji żądania
        zapewnienia dostępności lub alternatywnego sposobu dostępu do
        informacji, wnoszący żądanie możne złożyć skargę w sprawie zapewniana
        dostępności cyfrowej strony internetowej, aplikacji mobilnej lub
        elementu strony internetowej, lub aplikacji mobilnej. Po wyczerpaniu
        wskazanej wyżej procedury można także złożyć wniosek do{" "}
        <a href="https://www.rpo.gov.pl/">Rzecznika Praw Obywatelskich</a>.
      </p>
      <h2 className="bold">Dostępność architektoniczna</h2>
      <h3 className="bold"> Dostępność architektoniczna siedziby podmiotu: </h3>
      <p>
        Budynek szkoły posiada podjazd dla osób niepełnosprawnych przy głównym
        wejściu do szkoły oraz platformę dla niepełnosprawnych przy wejściu na
        halę sportową. Budynek nie posiada wind.
      </p>
      <h3 className="bold">Dostępność parkingu:</h3>
      <p>Budynek posiada parking.</p>
      <h3 className="bold">Dostępność toalet:</h3>
      <p>
        W budynku dostępne są toalety do interesantów oraz toalety dla
        niepełnosprawnych.
      </p>
      <h3 className="bold">Inne:</h3>
      <p>
        W budynku nie ma pętli indukcyjnych, informacji głosowych, tablic w
        alfabecie Braill’a ani oznaczeń dla osób niewidomych i niesłyszących.
      </p>

      <a
        href="../../../documents/Deklaracja_dostepnosci.docx"
        download="deklaracja_dostepnosci.docx"
        data-download
      >
        Deklaracja dostępności (18KB | docx)
      </a>
    </div>
  )
}

export default AccessibilityDeclarationTemplate
